export const company =
{
    name: 'RE/MAX Paradise Properties Krabi ',
    welcomeName: 'buyinkrabi.com',
    mainServiceArea: 'Ao Nang, Krabi',
    navPhone: '+66 62 961 5821',
    phone1: '062 961 5821 (ENG/TH)',
    email: 'paradiseproperties@remax.co.th',
    address1: '342/1 Moo 2 Aonang, Krabi',
    address2: 'Thailand',
    phoneUrl: 'tel:0066629615821',
    lineUrl: 'https://line.me/ti/p/jYMeqir21A',
    whatsappUrl: 'https://wa.me/66629615821',
    youtubeUrl: '',
    facebookUrl: 'https://www.facebook.com/paradisepropertie',
    instagramUrl: '',
    tiktokUrl: '',
    linkedInUrl: ''
}
