
              const i18n = {
                defaultLocale: 'default',
                locales: ["default", "en", "th", "cn"],
                localeDetection: false,
              };
              
              const Locale = i18n.locales;
              
              module.exports = { i18n, Locale };
              
              